import { defineStore } from 'pinia';
// import { computed, ref } from 'vue';

export const useImportPatientsStore = defineStore({
  id: 'importPatients',
  state: () => ({
    establishments: [],
    importType: '', // manual, file_one_establishment, file_multiple_establishments
    sendingOption: '',
    sendingMoment: '',
    sendingDate: '',
    redirectionType: '',
    survey: {}, // { id:, type: "survey or survey_schema" }
    sendEsatisEmailing: false,
    patient: {}, // { prefix, firstName, lastName, email, phoneNumber, tagId, exitDate }
    file: {}, // { useTagColumn, tagColumn, file, fileUrl }
  }),
  getters: {
    getPatientParams: state => {
      return {
        patient: state.patient,
        sendingOption: state.sendingOption,
        sendingMoment: state.sendingMoment,
        sendingDate: state.sendingDate,
        redirectionType: state.redirectionType,
        survey: state.survey,
        sendEsatisEmailing: state.sendEsatisEmailing,
      };
    },
    getFileParams: state => {
      return {
        file: state.file,
        sendingOption: state.sendingOption,
        sendingMoment: state.sendingMoment,
        sendingDate: state.sendingDate,
        redirectionType: state.redirectionType,
        survey: state.survey,
        sendEsatisEmailing: state.sendEsatisEmailing,
        establishments: state.establishments,
        importType: state.importType,
      };
    },
  },
  actions: {
    udpateSettings({ sendingOption, sendingMoment, sendingDate, redirectionType, survey, sendEsatisEmailing }) {
      this.sendingOption = sendingOption;
      this.sendingMoment = sendingMoment;
      if (sendingMoment === 'later') {
        this.sendingDate = sendingDate;
      }
      this.redirectionType = redirectionType;
      if (redirectionType === 'survey') {
        this.survey = survey;
      }
      this.sendEsatisEmailing = sendEsatisEmailing;
    },
    updateEstablishments({ establishments }) {
      this.establishments = establishments;
    },
    updatePatient({ patient }) {
      this.patient = patient;
    },
    updateFile({ file }) {
      this.file = file;
      if (file.useTagColumn === false) {
        this.file.tagColumn = null;
      }
      if (this.sendEsatisEmailing === false) {
        this.file.exitDateColumn = null;
      }
    },
    updateImportType({ importType }) {
      this.importType = importType;
    },
  },
});
